import React  from 'react';
import config from "../../config.json"
import './google_ads_footer.css';


function init(){
   //----< init() >----
    // (window.adsbygoogle = window.adsbygoogle || []).push(
    //   // {
    //   // google_ad_client: config.google_ad_client,
    //   // // enable_page_level_ads: true
    //   // }
    // );
    //----</ init() >----
}
init(); //*javascript.autostart

export default function GoogleAds_Footer() { 
  //----< HTML-Render >----
  return (
    //----< return: render >----
    <div className="div_google_ads_footer">
      <ins className="adsbygoogle"
        data-ad-client={config.google_ad_client}
        data-ad-slot="9297901752"       
        data-ad-format="auto"
        data-full-width-responsive="true"
       ></ins>
    </div>
    //----</ return: render >----
  );
  //----</ HTML-Render >----
    //============</ Component >============
  }
