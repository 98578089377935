import React  from 'react';
import config from "../../config.json"
import './google_ads_side.css';


function init(){
   //----< init() >----
    // (window.adsbygoogle = window.adsbygoogle || []).push(
    //   {
    //   google_ad_client: config.google_ad_client,
    //   enable_page_level_ads: true
    //   }
    // );
    //----</ init() >----
}
init(); //*javascript.autostart

export function GoogleAds_Side250() { 
  //----< HTML-Render >----
  return (
    //----< return: render >----
    <div className="div_google_ads_side_250">
      <ins className="adsbygoogle"
      data-ad-client={config.google_ad_client}      
      data-ad-slot="4863940522"      
      ></ins>
    </div>
    //----</ return: render >----
  );
  //----</ HTML-Render >----
    //============</ Component >============
  }

  export  function GoogleAds_Side600() { 
    //----< HTML-Render >----
    return (
      //----< return: render >----
      <div className="div_google_ads_side_600">
        <ins className="adsbygoogle"
        data-ad-client={config.google_ad_client}
        data-ad-slot="2220983665"       
       ></ins>
      </div>
      //----</ return: render >----
    );
    //----</ HTML-Render >----
      //============</ Component >============
    }
  