import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';  //auth_token
import { Fab } from '@mui/material';        //edit button
import EditIcon from '@mui/icons-material/Edit'; //edit button-icon

import {replace_link_to_fileserver} from "../../../helpers/texthelpers.ts";
import {GoogleAds_Side250, GoogleAds_Side600} from "../../ads/google_ads_side";

import "./article_read.css"


export default function ArticleReadComponent() {
  //============< Component >============
  //--< Variables >--
  //get id from url-parameter : read?id=123
  const [searchParams] = useSearchParams();
  const guidArticle = searchParams.get('i');
  
  const api_url = process.env.REACT_APP_API_URL;
  //--</ Variables >--
  
  

  //--< Variables >--
  const [item, setItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false); //button:->edit
  const [cookies, setCookie, removeCookie] = useCookies(['ut','XSRF-TOKEN']);
  //--</ Variables >--

  //--< Init: Hooks >--
  useEffect(() => {fetchData();}, []); // Pass an empty array to only call the function once on mount.
  useEffect(() => {init();}, []);

  function init(){
    // console.log("ut=" + cookies.auth_token);
    // console.log("antiforgery=" + cookies['XSRF-TOKEN']);
  }
  
  //--</ Init: Hooks >--


  //====< functions >====
  //*load data from web api
  async function fetchData() {
    setIsLoading(true);
    try {

        //--< prepare send >--
        const auth_token = cookies['ut'];
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth_token}`, //*enthält userguid u
            }
        };
       const url= api_url + '/articles/get_article_read/' + guidArticle;
       const response = await fetch(url,requestOptions );   //*get web_data from web api as json-string
       const apidata = await response.json();  //*convert json to data

       //< alten Bild-pfad tauschen >
        let content_Html=apidata.content_Html;
        if(content_Html !=""){
         let correct_ImageHtml=replace_link_to_fileserver(content_Html);
         apidata.content_Html=correct_ImageHtml;
        }
        //</ alten Bild-pfad tauschen >

        //< browser title >
        let content_Title=apidata.content_Title;
        document.title=content_Title
        //< browser title >
       setIsEdit(apidata.isEditable) 
       setItem(apidata);
    } catch (error) {
       console.error(error);
    } finally {
       setIsLoading(false);
    }
  }


  //----< HTML-Render >----
  if (isLoading) {    return <div style={{ color: 'black' }}>Loading...</div>; }
  if (item) {
    return (
      <div className="div_arcticle_read_component">       
        <div className="div_article_read">
          <div className="div_head_row">
            <div id="divshortlinks" className="divshortlinks">
              <div id="divkeywords" className="divkeywords" >
                { item.keywords !== null
                  ? <div className="divkeywords">
                    <img src="/icons/text-search.png" alt='keywords' className="icon_list_shortlinks"/>    
                    { (item.keywords).split(";").map(keyword =>
                      <a href={ "/articles/articles_list?k=" + encodeURI(keyword)}
                      style={{ margin: '4px' }} className="item_keyword"
                        key={keyword}>
                        {keyword}</a>     
                      )}                            
                  </div >
                  : <>                           
                    </>
                }
              </div>                
              <div id="divfolders"  >
                { item.folders !== null
                  ? <div className="divfolders" >
                    <img src="/icons/layers-search.png" alt='keywords' className="icon_list_shortlinks"/>    
                    { (item.folders).split(";").map(folder =>
                      <a href={ "/articles/articles_list?f=" + encodeURI(folder)}
                      style={{ margin: '4px' }} className="item_folder"
                      key={folder}
                      >                    
                        {folder}</a>     
                      )}                            
                  </ div>
                  : <>                           
                    </>
                }
              </div>
            </div>
          
            <div className="div_edit_button">
            {isEdit==true &&             
                <a href={'/articles/article_edit?i=' + guidArticle}><Fab color="secondary" aria-label="edit">
                  <EditIcon />
                </Fab>
                </a>}
            </div>
            </div>
          
          <h1 id="divtitle" className="title">{item.content_Title}</h1>

          <div className="item_text" dangerouslySetInnerHTML={{ __html: item.content_Html }} />
         </div>  
         <div className="div_ads_right">
         <GoogleAds_Side250/>
         <GoogleAds_Side600/>
         </div>  
      </div>          
    //----</ HTML-Render >----
    )
  }
  //============</ Component >============


}






